<template>
  <div class="service-setting">
    <PageTitle
      title="附加服務設定"
      cyBtn1="new-attach-service-btn"
      btn="新增"
      @btnClick="openDialog('create')"
    />

    <FiltersContainer>
      <el-input v-model="nameSearch" clearable placeholder="輸入車次名稱" @keypress.enter.native="refresh" @clear="refresh">
        <i slot="suffix" class="el-input__icon el-icon-search" @click="refresh" />
      </el-input>
    </FiltersContainer>

    <section>
      <el-table v-loading="loading" :data="serviceList" empty-text="暫無數據">
        <EmptyBlock slot="empty" />
        <el-table-column prop="name" label="名稱" width="150" align="center" />
        <el-table-column prop="isPublic" label="公開顯示" width="120" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.isPublic)">{{ (scope.row.isPublic)? '是' : '否' }}</Tag>
          </template>
        </el-table-column>
<!--        <el-table-column prop="price" label="實際價格" align="center" />-->
        <el-table-column prop="showPrice" label="顯示價格" align="center">
          <template slot-scope="scope">
            {{ (! scope.row.showPrice || scope.row.showPrice === '0')? '-' : scope.row.showPrice }}
          </template>
        </el-table-column>
        <el-table-column prop="bookingTime" label="實際預約時間" align="center" />
        <el-table-column prop="showTime" label="顯示預約時間" align="center">
          <template slot-scope="scope">
            {{ (! scope.row.showTime || scope.row.showTime === 0)? '-' : scope.row.showTime }}
          </template>
        </el-table-column>
        <el-table-column prop="order" label="排序" align="center" />
        <el-table-column prop="name" label="操作" fixed="right" width="110" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              @edit="openDialog('update'), syncFormData(scope.row), selectRow=scope.row, findAttachService()"
              @delete="deleteDialog = true, selectRow=scope.row"
            />
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="serviceCount"
        @pageChange="refresh"
      />
    </section>

    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" @close="resetForm()">
      <el-form ref="form" :model="formData" label-position="top" :rules="formRules">
        <el-form-item label="名稱" prop="name">
          <el-input v-model="formData.name" :maxlength="64" show-word-limit data-cy="name-input" placeholder="名稱" />
        </el-form-item>
        <el-form-item label="公開顯示">
          <el-switch
            v-model="formData.isPublic"
            data-cy="public-switch"
            active-text="是"
            inactive-text="否"
          />
        </el-form-item>
        <el-form-item label="實際預約時間(分鐘)" prop="bookingTime">
          <div class="flex flex-col">
            <el-input v-model="formData.bookingTime" data-cy="service-time-input" placeholder="請輸入實際預約時間" />
            <el-checkbox v-model="formData.hideTime" data-cy="hide-service-time">隱藏實際預約時間</el-checkbox>
          </div>
        </el-form-item>
        <el-form-item v-if="formData.hideTime" label="顯示預約時間" prop="showTime">
          <el-input v-model="formData.showTime" data-cy="show-service-time-input" placeholder="自定義 只允許數字  (0 視為不隱藏，將顯示實際預約時間)" />
        </el-form-item>
<!--        <el-form-item label="實際價格" prop="price">-->
<!--          <div class="flex flex-col">-->
<!--            <el-input v-model="formData.price" data-cy="service-price-input" placeholder="請輸入實際價格" />-->
<!--            <el-checkbox v-model="formData.hidePrice" data-cy="hide-service-price">隱藏實際價格</el-checkbox>-->
<!--          </div>-->
<!--        </el-form-item>-->
        <el-form-item v-if="formData.hidePrice" label="顯示價格" prop="showPrice">
          <el-input v-model="formData.showPrice" :maxlength="40" show-word-limit data-cy="show-service-price-input" placeholder="自定義 範例: 500元起跳  (0 視為不隱藏，將顯示實際預約時間)" />
        </el-form-item>
        <el-form-item label="排序" prop="order">
          <el-input v-model="formData.order" data-cy="order-input" placeholder="請輸入排序" />
        </el-form-item>
        <el-form-item label="綁定服務項目" prop="services">
          <UnitServicesSelect data-cy="service-binding-select" :model.sync="formData.services" multiple :showAll="true" />
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button data-cy="dialog-cancel-btn" plain @click="showDialog=false, resetForm()">取消</el-button>
        <el-button data-cy="dialog-confirm-btn" type="primary" @click="dialogConfirm">新增</el-button>
      </div>
    </el-dialog>

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteAttachService(), deleteDialog = false"
    />
  </div>
</template>

<script>
import UnitServicesSelect from '@/components/Select/UnitServicesSelect.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { CreateAttachService, GetAttachService, GetAttachServiceCount, DeleteAttachService, UpdateAttachService, FindAttachService } from '@/api/attachService'
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
// Utils
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import { extractList } from '@/utils/helper'
import { dialogTitle } from '@/utils/dialog'
import { mapGetters } from 'vuex'

export default {
  name: 'AttachServiceSetting',
  components: { DeleteDialog, UnitServicesSelect, EmptyBlock },
  computed: {
    ...mapGetters([
      'shop',
    ]),
    dialogTitle () {
      return dialogTitle(
        this.dialogType,
        {
          create: '新增附加服務',
          update: '編輯附加服務',
        },
      )
    },
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },

    shopList () {
      return this.$store.state.shopList
    },

    categoryList () {
      const list = []
      this.formData.serviceCategorys.forEach(item => {
        list.push(item.id)
      })
      return list
    },

    serviceUnitList () {
      const list = []
      this.formData.serviceUnits.forEach(item => {
        list.push(item.id)
      })
      return list
    },

  },
  data: () => ({
    nameSearch: '',
    loading: false,
    showDialog: false,
    deleteDialog: false,
    dialogType: '',

    selectRow: null,
    selectService: null,

    serviceList: [],
    serviceCount: 0,

    formData: {
      name: '',
      isPublic: true,
      price: 0,
      hideTime: false,
      hidePrice: false,
      bookingTime: '',
      showPrice: '',
      showTime: '',
      order: 100,
      services: [],
    },

    formRules: {
      name: noEmptyRules(),
      price: [noEmptyRules(), isDigitRules(), rangeRules()],
      showTime: [isDigitRules(), rangeRules()],
      // showPrice: noEmptyRules(),
      bookingTime: [noEmptyRules(), isDigitRules(), rangeRules()],
      order: [noEmptyRules(), isDigitRules(), rangeRules()],
    },

    tableOptions: {
      page: 1,
      pageLimit: 10,
    },
  }),

  async mounted () {
    await this.refresh()
  },

  methods: {
    tagType (val) {
      let type = 'info'
      if (val) type = 'action'
      // if (!val) type = 'danger'
      return type
    },
    async refresh () {
      this.loading = true
      await this.getAttachService()
      await this.getAttachServiceCount()
      this.loading = false
    },

    async dialogConfirm () {
      if (!await this.checkForm()) return
      const type = this.dialogType
      if (type === 'create') this.createAttachService()
      if (type === 'update') this.updateAttachService()
      this.showDialog = false
    },

    syncFormData (row) {
      this.formData.name = row.name
      this.formData.description = row.description
      this.formData.price = row.price
      if (row.showPrice && row.showPrice !== '0') {
        this.formData.hidePrice = true
        this.formData.showPrice = row.showPrice
      }
      if (row.showTime && row.showTime !== '0') {
        this.formData.hideTime = true
        this.formData.showTime = row.showTime
      }
      this.formData.bookingTime = row.bookingTime
      this.formData.order = row.order
      this.formData.isPublic = row.isPublic
    },

    //= > 檢查表單輸入驗證
    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    //= > 重置表單
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.formData = {
        name: '',
        isPublic: true,
        price: '',
        hideTime: false,
        hidePrice: false,
        showPrice: '',
        bookingTime: '',
        showTime: '',
        order: 100,
        services: [],
      }
    },

    //= > 創建服務
    async createAttachService () {
      try {
        const form = this.formData
        if (!form.hidePrice) form.showPrice = '0'
        if (!form.hideTime) form.showTime = '0'
        await CreateAttachService({
          shopId: this.shop,
          name: form.name,
          isPublic: form.isPublic,
          price: form.price,
          showPrice: (form.showPrice === '') ? '0' : form.showPrice,
          showTime: (form.showTime === '') ? 0 : form.showTime,
          bookingTime: form.bookingTime,
          order: form.order,
          appointmentServices: extractList('id', form.services),
        })
        await this.refresh()
        this.$message.success('新增成功!')
      } catch (error) {
        console.log(error)
        this.$message.warning(error)
      }
    },

    //= > 刪除服務
    async deleteAttachService () {
      try {
        await DeleteAttachService({
          shopId: this.selectRow.ShopId,
          id: this.selectRow.id,
        })

        await this.refresh()
        this.$message.success('刪除成功!')
      } catch (error) {
        console.log(error)
        this.$message.warning(error)
      }
    },

    //= > 更新服務
    async updateAttachService () {
      try {
        const form = this.formData
        if (!form.hidePrice) form.showPrice = '0'
        if (!form.hideTime) form.showTime = '0'
        await UpdateAttachService({
          shopId: this.shop,
          id: this.selectRow.id,
          name: form.name,
          isPublic: form.isPublic,
          price: form.price,
          showPrice: (form.showPrice === '') ? '0' : form.showPrice,
          showTime: (form.showTime === '') ? 0 : form.showTime,
          bookingTime: form.bookingTime,
          order: form.order,
          appointmentServices: extractList('id', form.services),
        })

        await this.refresh()
        this.$message.success('更新成功!')
      } catch (error) {
        console.log(error)
        this.$message.warning(error)
      }
    },

    //= > 取得服務
    async getAttachService () {
      try {
        const startIndex = this.pageStartIndex
        const limit = this.tableOptions.pageLimit
        const res = await GetAttachService({
          shopId: this.shop,
          start: startIndex,
          limit,
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })
        this.serviceList = res
      } catch (error) {
        console.log(error)
        this.$message.warning(error)
      }
    },

    async findAttachService () {
      try {
        const res = await FindAttachService({
          shopId: this.shop,
          id: this.selectRow.id,
        })
        this.formData.services = res.AppointmentServices.filter(item => !item.isRemove)
      } catch (error) {
        console.log(error)
        this.$message.warning(error)
      }
    },

    //= > 取得服務總數
    async getAttachServiceCount () {
      try {
        const res = await GetAttachServiceCount({
          shopId: this.shop,
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })
        this.serviceCount = res.data.count
      } catch (error) {
        console.log(error)
        this.$message.warning(error)
      }
    },

    openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },
  },
}
</script>

<style scoped lang="scss">
</style>
